import React from "react";
import { Button } from "semantic-ui-react";

class SaveBudget extends React.Component {
  render() {
    const obj = this.props.budget.document;
    let data =
      "text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(obj));
    return (
      <Button>
        <a
          href={"data:" + data}
          download={
            this.props.budget.document.company.companyName +
            "-" +
            this.props.budget.document.project.projectName +
            ".json"
          }
        >
          Export
        </a>
      </Button>
    );
  }
}

export default SaveBudget;
