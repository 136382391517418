import React from "react";
import { Page, Text, View, StyleSheet, Image, Font } from "@react-pdf/renderer";
//import "nested-list/public/fonts/Adieu-Bold.otf";

Font.register({
  family: "Adieu",
  fonts: [
    {
      src: `fonts/Adieu-Bold.ttf`,
      fontWeight: "bold",
      fontStyle: "normal"
    }
  ]
});
Font.register({
  family: "Sailec",
  fonts: [
    {
      src: `fonts/Sailec-Light.ttf`,
      fontWeight: "normal",
      fontStyle: "normal"
    },
    {
      src: `fonts/Sailec-Bold.ttf`,
      fontWeight: "bold"
    }
  ]
});
const styles = StyleSheet.create({
  page: { backgroundColor: "#FEC527" },
  section: { textAlign: "left", margin: 50 },
  logo: { width: "50%" },
  illustration: { width: "50%", position: "absolute", right: 0, top: 60 },
  mainText: { paddingTop: "300px" },
  title: { fontFamily: "Adieu", fontSize: 72 },
  estimate: { paddingTop: "30px", fontFamily: "Sailec", fontSize: 24 },
  url: {
    fontFamily: "Sailec",
    fontWeight: "bold",
    fontSize: 18,
    position: "absolute",
    margin: 50,
    right: 0,
    bottom: 0
  }
});

export default function LastPage(props) {
  //   console.log(props.data);
  //   console.log(props.data.project.projectName);
  return (
    <Page style={styles.page}>
      <View style={styles.section}>
        <Image src="cleevio_logo.png" style={styles.logo} />
        <Image src="services_footer.png" style={styles.illustration} />
        <View style={styles.mainText}>
          <Text style={styles.title}>Let's </Text>
          <Text style={styles.title}>make it </Text>
          <Text style={styles.title}>happen</Text>
        </View>
      </View>
      <View style={styles.url}>
        <Text>Cleevio.com</Text>
      </View>
    </Page>
  );
}
