import React from "react";
import { PDFDownloadLink } from "@react-pdf/renderer";
import Template from "./Template";
import { Button } from "semantic-ui-react";

import BudgetStore from "./../stores/BudgetStore";

class GeneratePdf extends React.Component {
  render() {
    let title = "export";
    const getTitle = BudgetStore.getTitle();
    if (getTitle.length > 1) {
      title = getTitle;
    }

    return (
      <Button className="content">
        <PDFDownloadLink
          document={<Template budget={BudgetStore.getAll()} />}
          fileName={title + ".pdf"}
        >
          {({ blob, url, loading, error }) =>
            loading ? "Loading document..." : "Download PDF"
          }
        </PDFDownloadLink>
      </Button>
    );
  }
}

export default GeneratePdf;
