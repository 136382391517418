import React from "react";
import { Document, Font } from "@react-pdf/renderer";

import TitlePage from "./pdfComponents/TitlePage";
import Content from "./pdfComponents/Content";
import Summary from "./pdfComponents/Summary";
import LastPage from "./pdfComponents/LastPage";

export default function Template(props) {
  Font.register({
    family: "Sailec",
    fonts: [
      {
        src: `fonts/Sailec-Light.ttf`,
        fontWeight: "normal",
        fontStyle: "normal"
      }
    ]
  });

  return (
    <Document>
      <TitlePage data={props.budget.document} />
      <Content data={props.budget.document} />
      <Summary data={props.budget.document} />
      <LastPage />
    </Document>
  );
}
