import React from "react";
import { Text, View, StyleSheet, Font } from "@react-pdf/renderer";

Font.register({
  family: "Sailec",
  fonts: [
    {
      src: `fonts/Sailec-Light.ttf`,
      fontWeight: "normal",
      fontStyle: "normal"
    }
  ]
});

const styles = StyleSheet.create({
  section: {
    margin: "auto",
    flexDirection: "row",
    width: "100%",
    display: "block"
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
    fontFamily: "Sailec",
    borderStyle: "solid",
    borderColor: "#e6e3e3",
    paddingTop: "5px",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    borderRightWidth: 0
  },
  // 1px solid rgba(28, 37, 43, 0.05);
  tableCol: {
    width: "50%",
    borderStyle: "solid",
    borderColor: "#bfbfbf",
    borderWidth: 0,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },

  tableCell: {
    margin: 5,
    fontSize: 12
  },
  tableCellRight: {
    margin: 5,
    fontSize: 12,
    textAlign: "right"
  }
});

export default function TemplateList(props) {
  let children = null;

  if (props.budget.items && props.budget.items.length) {
    children = (
      <View>
        {props.budget.items.map(item => (
          <View key={item.id} style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{item.name}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCellRight}>{item.estimate}</Text>
            </View>
          </View>
        ))}
      </View>
    );
  }
  return <View>{children}</View>;

  // <Text>{children}</Text>;
}
