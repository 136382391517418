import React from "react";
import { Page, Text, View, StyleSheet, Font } from "@react-pdf/renderer";
import Header from "./Header";
import numeral from "numeral";

Font.register({
  family: "Adieu",
  fonts: [
    {
      src: `fonts/Adieu-Bold.ttf`,
      fontWeight: "bold",
      fontStyle: "normal"
    }
  ]
});
Font.register({
  family: "Sailec",
  fonts: [
    {
      src: `fonts/Sailec-Light.ttf`,
      fontWeight: "normal",
      fontStyle: "normal"
    },
    {
      src: `fonts/Sailec-Bold.ttf`,
      fontWeight: "bold"
    }
  ]
});

const styles = StyleSheet.create({
  table: {
    margin: 50,
    display: "table",
    width: "175mm",
    borderStyle: "solid",
    borderColor: "#bfbfbf",
    borderWidth: 0,
    borderRightWidth: 0
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
    paddingTop: "5px"
  },
  tableSection: {
    paddingBottom: 40
  },
  tableColHeader: {
    width: "100%",
    borderStyle: "solid",
    borderWidth: 0.5,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    borderRightWidth: 0,
    verticalAlign: "super"
  },
  tableCol: {
    width: "50%",
    borderStyle: "solid",
    borderColor: "#bfbfbf"
  },
  tableCellHeaderLeft: {
    height: "40",
    fontSize: 24,
    fontFamily: "Adieu"
  },
  tableCellHeader: {
    height: "30",
    width: "100%",
    fontSize: 18,
    textAlign: "right",
    fontFamily: "Sailec",
    position: "absolute",
    right: 0,
    bottom: 0
  },
  tableCell: {
    //margin: "auto",
    fontFamily: "Sailec",
    margin: 5,
    fontSize: 12
  },
  tableCellRight: {
    //margin: "auto",
    fontFamily: "Sailec",
    margin: 5,
    fontSize: 12,
    textAlign: "right"
  },
  tableCellSummary: {
    fontFamily: "Sailec",
    fontWeight: "bold",
    margin: 5,
    fontSize: 12
  },
  tableCellSummaryRight: {
    fontFamily: "Sailec",
    fontWeight: "bold",
    margin: 5,
    fontSize: 12,
    textAlign: "right"
  },
  header: {
    width: "100%",
    height: "100px",
    backgroundColor: "#FEC527"
  }
});

export default function Summary(props) {
  return (
    <Page style={styles.page}>
      <Header data={props.data} />
      <View style={styles.table}>
        <View style={styles.tableRow}>
          <View style={styles.tableColHeader}>
            <Text style={styles.tableCellHeaderLeft}>Quote summary</Text>
          </View>
        </View>

        {props.data.budget
          ? props.data.budget.map((i, index) => {
              return (
                <View key={index}>
                  <View style={styles.tableRow}>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>
                        {i.name + " - " + i.totalMD + " MD's"}
                      </Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCellRight}>
                        {numeral(i.totalPrice).format("0,0")}
                        {" " + props.data.project.currency}
                      </Text>
                    </View>
                  </View>
                </View>
              );
            })
          : ""}
        <View style={styles.tableColHeader} />
        <View style={styles.tableRow}>
          <View style={styles.tableCol}>
            <Text style={styles.tableCellSummary}>{"Total mandays"}</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCellSummaryRight}>
              {props.data.totalSummary.totalMd + " MD's"}
            </Text>
          </View>
        </View>

        <View style={styles.tableRow}>
          <View style={styles.tableCol}>
            <Text style={styles.tableCellSummary}>{"Total price "}</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCellSummaryRight}>
              {numeral(props.data.totalSummary.totalPrice).format("0,0")}
              {" " + props.data.project.currency}
            </Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>All prices are without VAT</Text>
          </View>
          <View style={styles.tableCol}></View>
        </View>
      </View>
    </Page>
  );
}
