import React from "react";
import { Menu } from "semantic-ui-react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const items = [
  { key: "editor", active: true, disabled: false, name: "New Budget" }
];

export const StyledMenu = styled(Menu)({
  margin: "0px!important"
});
class MenuBar extends React.Component {
  state = {};
  handleItemClick = (e, { name, key }) => {
    this.setState({ activeItem: name });
  };
  render() {
    const { activeItem } = this.state;
    return (
      <StyledMenu>
        <Menu.Menu position="left">
          {items.map(i => (
            <Link to={"/" + i.key} key={i.key}>
              <Menu.Item
                key={i.key}
                name={i.name}
                active={activeItem === i.name}
                disabled={i.disabled}
                onClick={this.handleItemClick}
              />
            </Link>
          ))}
        </Menu.Menu>
        <Menu.Menu position="right">
          <Menu.Item name="Profile" disabled={true} />
        </Menu.Menu>
      </StyledMenu>
    );
  }
}

export default MenuBar;
