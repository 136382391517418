import React from "react";
import { Table, Input, Dropdown } from "semantic-ui-react";

const currencies = [
  {
    key: "USD",
    text: "USD",
    value: "USD"
  },
  {
    key: "CZK",
    text: "CZK",
    value: "CZK"
  },
  {
    key: "EUR",
    text: "EUR",
    value: "EUR"
  }
];

class ListProfesionRates extends React.Component {
  render() {
    let mdRateType = "mdRate";
    let currency = "currency";
    console.log(this.props);
    return (
      <div>
        <Table>
          <Table.Body>
            <Table.Row>
              <Table.Cell>Profession MD's rates: </Table.Cell>
              <Table.Cell>
                <Dropdown
                  // defaultValue={this.props.currency}
                  value={this.props.currency}
                  options={currencies}
                  onChange={(event, { value }) =>
                    this.props.handleChange(
                      event,
                      this.props.budget,
                      currency,
                      value
                    )
                  }
                />
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Profession</Table.HeaderCell>
              <Table.HeaderCell style={{ width: "200px" }}>
                MD rate
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {this.props.list.map(profession => (
              <Table.Row key={profession.key}>
                <Table.Cell>{profession.text}</Table.Cell>
                <Table.Cell>
                  <Input
                    label={{ basic: true, content: this.props.currency }}
                    labelPosition="right"
                    style={{ width: "50px" }}
                    type="number"
                    transparent
                    value={profession.price}
                    onBlur={e => {
                      this.props.handleChange(e, null, mdRateType, profession);
                    }}
                  />
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </div>
    );
  }
}

export default ListProfesionRates;
