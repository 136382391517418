import dispatcher from "../dispatcher";

export function createSection() {
  dispatcher.dispatch({
    type: "CREATE_SECTION"
  });
}

export function deleteSection(budget) {
  dispatcher.dispatch({
    type: "DELETE_SECTION",
    budget
  });
}

export function createRow(event, budgetSectionId) {
  dispatcher.dispatch({
    type: "CREATE_ROW",
    event,
    budgetSectionId
  });
}

export function deleteRow(item, budgetSection) {
  dispatcher.dispatch({
    type: "DELETE_ROW",
    item,
    budgetSection
  });
}

export function handleChange(
  event,
  budgetSection = null,
  typeChange,
  item = null
) {
  dispatcher.dispatch({
    type: "HANDLE_ACTION",
    event,
    budgetSection,
    typeChange,
    item
  });
}

export function importBudget(budget) {
  dispatcher.dispatch({
    type: "HANDLE_IMPORT",
    budget
  });
}
