import React from "react";
import { Switch, Route } from "react-router-dom";
import PageNotFound from "./components/pages/other/PageNotFound";
import Routes from "./routes/routes";
import PageWrapper from "./components/helpers/PageWrapper";
import Editor from "./components/pages/editor/Editor";

export const AppRoutes = () => (
  // <Switch>
  //   {Routes.map(({ Component, path }) => (
  //     <Route
  //       exact
  //       path={path}
  //       key={path}
  //       render={function(route) {
  //         return (
  //           <PageWrapper route={route}>
  //             <Component />
  //           </PageWrapper>
  //         );
  //       }}
  //     />
  //   ))}

  //   <Route component={PageNotFound} />
  // </Switch>
  <Switch>
    {/* {Routes.map(({ Component, path }) => (
      <Route
        exact
        path={path}
        key={path}
        render={function(route) {
          return (
            <PageWrapper route={route}>
              <Component />
            </PageWrapper>
          );
        }}
      />
    ))} */}
    {/* <Route
      exact
      path="/"
      key="/"
      render={function(route) {
        return (
          <PageWrapper route={route}>
            <Editor />
          </PageWrapper>
        );
      }}
    />
     <Redirect from="./" exact to="/" /> 
    <Route component={PageNotFound} /> */}
    <Route component={Editor} />
  </Switch>
);
