import { EventEmitter } from "events";
// import * as RowsActions from "./RowsActions";
import dispatcher from "../dispatcher";

class BudgetStore extends EventEmitter {
  constructor() {
    super();
    this.budget = {
      document: {
        budget: [
          {
            id: Date.now(),
            name: "",
            mdRateKey: 0,
            totalMD: 0,
            totalPrice: 0,
            items: [
              {
                id: Date.now(),
                name: "",
                estimate: 0
              }
            ]
          }
        ],
        company: {
          companyName: ""
        },
        project: {
          projectName: "",
          currency: "EUR",
          language: ""
        },
        mdRates: [
          { key: 0, text: "Designer", value: 0, price: 500 },
          { key: 1, text: "Developer", value: 1, price: 500 },
          { key: 2, text: "Project manager", value: 2, price: 400 },
          { key: 3, text: "Tester", value: 3, price: 300 }
        ],
        totalSummary: {
          totalMd: 0,
          totalPrice: 0
        }
      }
    };
  }

  getAll() {
    return this.budget;
  }

  getTitle() {
    return this.budget.document.project.projectName;
  }

  importBudget(budget) {
    //this.state.setIn({ budget });
    this.budget = { ...budget };
    console.log("store budget", budget);
    console.log("this.budget = ", this.budget);
    //  this.budget = budget;
    this.emit("change");
  }

  createSection() {
    this.budget.document.budget.push({
      id: Date.now(),
      name: "",
      mdRateKey: 0,
      totalMD: 0,
      totalPrice: 0,
      items: [
        {
          id: Date.now(),
          name: "",
          estimate: 0
        }
      ]
    });
    this.emit("change");
  }
  deleteSection(budget) {
    let id = this.budget.document.budget.findIndex(i => i.id === budget.id);
    console.log(id);
    this.budget.document.budget.splice(id, 1);
    this.emit("change");
  }

  createRow(event, budgetSectionId) {
    this.budget.document.budget.forEach(i => {
      if (i.id === budgetSectionId) {
        event.preventDefault();
        let item = [...this.budget.document.budget];
        let id = item.findIndex(i => i.id === budgetSectionId);
        item[id].items.push({
          id: Date.now(),
          name: "",
          estimate: 0
        });
      }
    });
    this.emit("change");
  }

  deleteRow(item, budgetSection) {
    let sectionId = this.budget.document.budget.findIndex(
      i => i.id === budgetSection.id
    );
    let itemId = this.budget.document.budget[sectionId].items.findIndex(
      i => i.id === item.id
    );
    this.budget.document.budget[sectionId].items.splice(itemId, 1);
    this.emit("change");
  }

  handleChange(event, budgetSection, typeChange, item) {
    let NewValue = event.target.value;
    event.preventDefault();

    switch (typeChange) {
      case "estimate": {
        let id = this.budget.document.budget.findIndex(
          i => i.id === budgetSection.id
        );
        for (let i = 0; i < this.budget.document.budget[id].items.length; i++) {
          let loopItem = this.budget.document.budget[id].items[i];

          if (item !== null) {
            if (loopItem.id === item.id) {
              if (typeChange === "estimate") {
                this.budget.document.budget[id].items[i].estimate = Number(
                  NewValue
                );
              } else {
                this.budget.document.budget[id].items[i].name = NewValue;
              }
              break;
            }
          } else {
            if (this.budget.document.budget[id].id === budgetSection.id) {
              this.budget.document.budget[id].name = NewValue;
            }
            break;
          }
        }
        break;
      }
      case "name": {
        let idSection = this.budget.document.budget.findIndex(
          i => i.id === budgetSection.id
        );
        for (
          let i = 0;
          i < this.budget.document.budget[idSection].items.length;
          i++
        ) {
          let loopItem = this.budget.document.budget[idSection].items[i];

          if (item !== null) {
            if (loopItem.id === item.id) {
              this.budget.document.budget[idSection].items[i].name = NewValue;

              break;
            }
          } else {
            if (
              this.budget.document.budget[idSection].id === budgetSection.id
            ) {
              // console.log("newState", newState);
              this.budget.document.budget[idSection].name = NewValue;
            }
            break;
          }
        }
        break;
      }
      case "company": {
        this.budget.document.company.companyName = NewValue;
        break;
      }
      case "project": {
        this.budget.document.project.projectName = NewValue;
        break;
      }
      case "profession": {
        let idRate = this.budget.document.mdRates.findIndex(
          i => i.id === item.id
        );
        this.budget.document.mdRates[idRate].profession = NewValue;
        break;
      }
      case "mdRate": {
        let idMdRate = this.budget.document.mdRates.findIndex(
          i => i.key === item.key
        );
        this.budget.document.mdRates[idMdRate].price = Number(NewValue);
        break;
      }
      case "professionSelect": {
        let idProfessionSelect = this.budget.document.budget.findIndex(
          i => i.id === budgetSection.id
        );
        let idRates = this.budget.document.mdRates.findIndex(
          i => i.key === item
        );
        this.budget.document.budget[
          idProfessionSelect
        ].mdRateKey = this.budget.document.mdRates[idRates].key;
        break;
      }
      case "currency": {
        this.budget.document.project.currency = item;
        break;
      }
      case "language": {
        this.budget.document.project.language = item;
        break;
      }
      default:
        this.setState(this.budget);
    }
    this.emit("change");
  }

  handleActions(action) {
    // eslint-disable-next-line default-case

    switch (action.type) {
      case "CREATE_SECTION": {
        this.createSection();
        break;
      }
      case "DELETE_SECTION": {
        this.deleteSection(action.budget);
        break;
      }
      case "CREATE_ROW": {
        this.createRow(action.event, action.budgetSectionId);
        break;
      }
      case "DELETE_ROW": {
        this.deleteRow(action.item, action.budgetSection);
        break;
      }
      case "HANDLE_ACTION": {
        this.handleChange(
          action.event,
          action.budgetSection,
          action.typeChange,
          action.item
        );
        break;
      }
      case "HANDLE_IMPORT": {
        this.importBudget(action.budget);
        break;
      }
      default: {
        console.log("store went wrong in Store");
      }
    }
  }
}

const budgetStore = new BudgetStore();
dispatcher.register(budgetStore.handleActions.bind(budgetStore));

export default budgetStore;
