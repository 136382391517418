import React from "react";
import {
  Button,
  Input,
  Divider,
  Grid,
  Icon,
  Table,
  Dropdown
} from "semantic-ui-react";
import "../editor.css";

const rowButton = {
  float: "right",
  marginBottom: "10px",
  display: "block"
};
class ListItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.props.budget;
  }

  render() {
    let children = null;
    let estimate = "estimate";
    let name = "name";
    let professionSelect = "professionSelect";
    let professionSelectIndex = this.props.profession.findIndex(
      i => i.key === this.props.budget.mdRateKey
    );

    if (this.props.budget.items && this.props.budget.items.length) {
      children = (
        <Table basic="very">
          <Table.Body>
            {this.props.budget.items.map(item => (
              <Table.Row key={item.id} className="table-row">
                <Table.Cell>
                  <Input
                    transparent
                    placeholder="Name of item"
                    type="text"
                    defaultValue={item.name}
                    onBlur={event =>
                      this.props.handleChange(
                        event,
                        this.props.budget,
                        name,
                        item
                      )
                    }
                  />
                </Table.Cell>
                <Table.Cell textAlign="right">
                  <Input
                    transparent
                    type="number"
                    defaultValue={item.estimate}
                    onBlur={event =>
                      this.props.handleChange(
                        event,
                        this.props.budget,
                        estimate,
                        item
                      )
                    }
                  />
                </Table.Cell>

                <Table.Cell textAlign="right">
                  <Icon
                    name="cancel"
                    size="large"
                    onClick={() =>
                      this.props.onRemoveRow(
                        item,
                        this.props.budget,
                        professionSelect
                      )
                    }
                  />
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      );
    }

    return (
      <Table.Row>
        <Table.Cell>
          <Input
            transparent
            placeholder="Name of section"
            type="text"
            className="bold"
            defaultValue={this.props.budget.name}
            onBlur={event =>
              this.props.handleChange(event, this.props.budget, name)
            }
          />
          <Dropdown
            placeholder="Profession related"
            options={this.props.profession}
            defaultValue={this.props.profession[professionSelectIndex].value}
            onChange={(event, { value }) =>
              this.props.handleChange(
                event,
                this.props.budget,
                professionSelect,
                value
              )
            }
          />

          <Icon
            name="cancel"
            size="large"
            onClick={event =>
              this.props.onRemoveSection(event, this.props.budget)
            }
          />

          <Divider />
          {children}
          <Grid width={8}>
            <Grid.Column>
              <Button
                style={rowButton}
                size="small"
                onClick={event =>
                  this.props.addRow(event, this.props.budget.id)
                }
              >
                Add row
              </Button>
            </Grid.Column>
          </Grid>
        </Table.Cell>
      </Table.Row>
    );
  }
}

export default ListItem;
