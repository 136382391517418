import React from "react";
import { Header } from "semantic-ui-react";

class HeaderPage extends React.Component {
  render() {
    return <Header as="h1">{this.props.title}</Header>;
  }
}

export default HeaderPage;
