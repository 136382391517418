import React, { Component } from "react";

export default class PageNotFound extends Component {
  render() {
    const box = {
      paddingTop: "100px",
      fontFamily: "Arial",
      margin: "auto"
    };
    const text = {
      textAlign: "center",
      margin: "auto"
    };
    return (
      <div style={box}>
        <h1 style={text}>Page not found</h1>
      </div>
    );
  }
}
