import React, { Component } from "react";
import Header from "./MenuBar";
import "./pageWrapper.css";

class PageWrapper extends Component {
  render() {
    const { children } = this.props;
    return (
      <React.Fragment>
        <Header />
        <div className="background">{children}</div>
      </React.Fragment>
    );
  }
}

export default PageWrapper;
