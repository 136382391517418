import React from "react";
import { Page, Text, View, StyleSheet, Font } from "@react-pdf/renderer";
import TemplateList from "./ContentList";
import Header from "./Header";
Font.register({
  family: "Adieu",
  fonts: [
    {
      src: `fonts/Adieu-Bold.ttf`,
      fontWeight: "bold",
      fontStyle: "normal"
    }
  ]
});
Font.register({
  family: "Sailec",
  fonts: [
    {
      src: `fonts/Sailec-Light.ttf`,
      fontWeight: "normal",
      fontStyle: "normal"
    },
    {
      src: `fonts/Sailec-Bold.ttf`,
      fontWeight: "bold"
    }
  ]
});

const styles = StyleSheet.create({
  body: {
    padding: 10
  },
  table: {
    display: "table",
    width: "175mm",
    borderStyle: "solid",
    borderColor: "#bfbfbf",
    borderWidth: 0,
    borderRightWidth: 0,
    margin: 50
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row"
  },
  tableSection: {
    paddingBottom: 40
  },
  tableColHeaderLeft: {
    width: "70%",
    borderStyle: "solid",
    //borderColor: "#bfbfbf",
    borderWidth: 0.5,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    borderRightWidth: 0,
    verticalAlign: "super"
  },
  tableColHeaderRight: {
    width: "30%",
    borderStyle: "solid",
    //borderColor: "#bfbfbf",
    borderWidth: 0.5,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    borderRightWidth: 0,
    verticalAlign: "super"
  },
  tableColHeader: {
    width: "50%",
    borderStyle: "solid",
    //borderColor: "#bfbfbf",
    borderWidth: 0.5,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    borderRightWidth: 0,
    verticalAlign: "super"
  },
  tableCol: {
    width: "50%",
    fontFamily: "Sailec",
    fontWeight: "bold"
  },
  tableCellHeaderLeft: {
    height: "40",
    fontSize: 20,
    fontFamily: "Adieu"
  },
  tableCellHeader: {
    height: "30",
    width: "100%",
    fontSize: 16,
    textAlign: "right",
    fontFamily: "Sailec",
    position: "absolute",
    right: 0,
    bottom: 0
  },
  tableCell: {
    margin: 5,
    fontSize: 12
  },
  tableCellRight: {
    margin: 5,
    fontSize: 12,
    textAlign: "right"
  },
  header: {
    width: "100%",
    height: "100px",
    backgroundColor: "#FEC527"
  },
  symbol: {
    height: "50px",
    width: "50px",
    position: "absolute",
    top: "25px",
    right: "50px"
  }
});

export default function Content(props) {
  return (
    <Page style={styles.page}>
      <Header data={props.data} />
      <View style={styles.table}>
        {props.data.budget
          ? props.data.budget.map((i, index) => {
              return (
                <View key={index} style={styles.tableSection}>
                  <View style={styles.tableRow}>
                    <View style={styles.tableColHeaderLeft}>
                      <Text style={styles.tableCellHeaderLeft}>{i.name}</Text>
                    </View>
                    <View style={styles.tableColHeaderRight}>
                      <Text style={styles.tableCellHeader}>Estimate(MD)</Text>
                    </View>
                  </View>
                  <View style={styles.row}>
                    <TemplateList budget={i} key={i.id} />
                  </View>
                  <View style={styles.tableRow}>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>Sub-total</Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCellRight}>{i.totalMD}</Text>
                    </View>
                  </View>
                </View>
              );
            })
          : ""}
      </View>
    </Page>
  );
}
