import React from "react";
import { Text, Image, View, StyleSheet, Font } from "@react-pdf/renderer";
import moment from "moment";

Font.register({
  family: "Sailec",
  fonts: [
    {
      src: `fonts/Sailec-Light.ttf`,
      fontWeight: "normal",
      fontStyle: "normal"
    }
  ]
});

const styles = StyleSheet.create({
  table: {
    display: "table",
    width: "155mm",
    position: "absolute",
    top: "30px",
    left: "50px"
  },
  tableRow: {
    flexDirection: "row",
    fontFamily: "Sailec"
  },
  tableColHeaderLeft: {
    width: "50px"
  },
  tableColHeader: {
    width: "200px",
    textAlign: "left",
    paddingRight: "10px"
  },
  // 1px solid rgba(28, 37, 43, 0.05);
  tableCol: {
    borderStyle: "solid",
    borderColor: "#bfbfbf",
    borderWidth: 0,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },

  tableCell: {
    margin: 5,
    fontSize: 10
  },
  tableCellRight: {
    margin: 5,
    fontSize: 10,
    textAlign: "right"
  },
  header: {
    width: "100%",
    height: "100px",
    backgroundColor: "#FEC527"
  },
  symbol: {
    height: "50px",
    width: "50px",
    position: "absolute",
    top: "25px",
    right: "50px"
  }
});

export default function Header(props) {
  return (
    <View style={styles.header}>
      <View style={styles.table}>
        <View style={styles.tableRow}>
          <View style={styles.tableColHeaderLeft}>
            <Text style={styles.tableCell}>Project</Text>
          </View>
          <View style={styles.tableColHeader}>
            <Text style={styles.tableCell}>
              {props.data.project.projectName}
            </Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={styles.tableColHeaderLeft}>
            <Text style={styles.tableCell}>Date</Text>
          </View>
          <View style={styles.tableColHeader}>
            <Text style={styles.tableCell}>
              {/* <Moment format="DD/MM/YYYY">{date}</Moment> */}
              {moment(new Date()).format("Do MMMM YYYY")}
            </Text>
          </View>
        </View>
      </View>
      <Image src="cleevio_symbol.png" style={styles.symbol} />
    </View>
  );

  // <Text>{children}</Text>;
}
