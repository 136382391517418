import React, { Component } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { AppRoutes } from "./AppRoutes";
import ScrollToTop from "react-router-scroll-top";

class App extends Component {
  render() {
    return (
      <Router>
        <ScrollToTop>
          <AppRoutes />
        </ScrollToTop>
      </Router>
    );
  }
}

export default App;
