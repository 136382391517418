import React from "react";
import ListItem from "./components/ListItem";
import ListProfesionRates from "./components/ListRates";
import GeneratePdf from "./../../../pdf/PdfGenerator";
import Summary from "./components/Summary";
import HeaderPage from "../../helpers/Header";
import { Grid, Button, Input, Table } from "semantic-ui-react";
import "./editor.css";
import * as BudgetActions from "../../../actions/budgetActions";
import BudgetStore from "../../../stores/BudgetStore";

import SaveBudget from "./components/SaveBudget";

import Files from "react-files";
class Editor extends React.Component {
  constructor() {
    super();
    this.getBudget = this.getBudget.bind(this);
    this.state = {
      budget: BudgetStore.getAll()
    };
    this.fileReader = new FileReader();
    this.fileReader.onload = event => {
      BudgetActions.importBudget(
        { document: JSON.parse(event.target.result) },
        () => {
          console.log("new state: ", event.target.result);
        }
      );
    };
  }

  componentWillMount() {
    BudgetStore.on("change", this.getBudget);
  }
  componentWillUnmount() {
    BudgetStore.removeListener("change", this.getBudget);
  }

  getBudget() {
    this.setState({
      budget: BudgetStore.getAll()
    });
  }

  handleChange = (event, budgetSection = null, type, item = null) => {
    BudgetActions.handleChange(event, budgetSection, type, item);
  };
  addSection = () => {
    BudgetActions.createSection();
  };

  addRow = (event, budgetSectionId) => {
    BudgetActions.createRow(event, budgetSectionId);
  };

  removeRow = (item, budgetSection) => {
    BudgetActions.deleteRow(item, budgetSection);
  };

  removeSection = budget => {
    BudgetActions.deleteSection(budget);
  };

  render() {
    return (
      <div className="margin-top">
        <div className="content">
          <HeaderPage title={"Budget maker"} />
          <Grid columns={2} divided>
            <Grid.Column computer={12} mobile={16}>
              <Grid.Row>
                <p>
                  For generate estimation, please fill following information..
                </p>
                <Grid.Row>
                  <Grid columns={3}>
                    <Grid.Column>
                      <Input
                        transparent
                        size="big"
                        defaultValue={
                          this.state.budget.document.company.companyName
                        }
                        placeholder="Company name"
                        onBlur={e => {
                          this.handleChange(e, null, "company");
                        }}
                      />
                    </Grid.Column>
                    <Grid.Column>
                      <Input
                        transparent
                        size="big"
                        defaultValue={
                          this.state.budget.document.project.projectName
                        }
                        placeholder="Project name"
                        onBlur={e => {
                          this.handleChange(e, null, "project");
                        }}
                      />
                    </Grid.Column>
                    <Grid.Column>
                      <Files
                        className="files-dropzone"
                        onChange={file => {
                          this.fileReader.readAsText(file[0]);
                        }}
                        onError={err => console.log(err)}
                        accepts={[".json"]}
                        multiple
                        maxFiles={3}
                        maxFileSize={10000000}
                        minFileSize={0}
                        clickable
                      >
                        <Button>Import</Button>
                      </Files>
                    </Grid.Column>
                  </Grid>
                </Grid.Row>
                <Table basic="very" className="padding">
                  <Table.Body>
                    {this.state.budget.document.budget.map(i => (
                      <ListItem
                        budget={i}
                        profession={this.state.budget.document.mdRates}
                        key={i.id}
                        onRemoveRow={this.removeRow}
                        onRemoveSection={this.removeSection}
                        handleChange={this.handleChange}
                        addRow={this.addRow}
                      />
                    ))}

                    <Table.Row>
                      <Table.Cell>
                        <Button
                          primary
                          onClick={() => {
                            this.addSection();
                          }}
                        >
                          Add section
                        </Button>
                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
                <Summary data={this.state.budget.document} />
                <Grid.Row>
                  <GeneratePdf />
                  <SaveBudget budget={this.state.budget} />
                </Grid.Row>
              </Grid.Row>
            </Grid.Column>
            <Grid.Column computer={4} mobile={16}>
              <Grid.Row>
                <ListProfesionRates
                  list={this.state.budget.document.mdRates}
                  currency={this.state.budget.document.project.currency}
                  handleChange={this.handleChange}
                />
              </Grid.Row>
            </Grid.Column>
          </Grid>
        </div>
      </div>
    );
  }
}

export default Editor;
