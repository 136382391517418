import React from "react";
import { Header, Table } from "semantic-ui-react";

import numeral from "numeral";

class Summary extends React.Component {
  state = {
    price: 0,
    mandays: 0
  };
  summarization(CategoryId) {
    let total = 0;
    let id = this.props.data.budget.findIndex(i => i.id === CategoryId);
    this.props.data.budget[id].items.forEach(item => {
      total += item.estimate;
    });
    this.props.data.budget[id].totalMD = total;
    return this.props.data.budget[id].totalMD;
  }
  price(CategoryId) {
    let price = 0;
    let id = this.props.data.budget.findIndex(i => i.id === CategoryId);
    this.props.data.budget[id].items.forEach(item => {
      price += item.estimate;
    });
    let professionIndex = this.props.data.mdRates.findIndex(
      i => i.key === this.props.data.budget[id].mdRateKey
    );

    if (
      this.props.data.budget[id].mdRateKey ===
      this.props.data.mdRates[professionIndex].key
    ) {
      this.props.data.budget[id].totalPrice =
        price * this.props.data.mdRates[professionIndex].price;
    }

    return this.props.data.budget[id].totalPrice;
  }
  total() {
    let price = 0;
    let mandays = 0;
    this.props.data.budget.forEach(i => {
      price += i.totalPrice;
      mandays += i.totalMD;
    });
    this.props.data.totalSummary.totalMd = mandays;
    this.props.data.totalSummary.totalPrice = price;
  }
  render() {
    return (
      <div>
        <hr />
        <Header size="medium">In total</Header>
        <Table basic="very">
          <Table.Body>
            {this.props.data.budget.map(category => (
              <Table.Row key={category.id}>
                <Table.Cell>
                  {category.name +
                    " - " +
                    this.summarization(category.id) +
                    "MD"}
                </Table.Cell>
                <Table.Cell textAlign="right">
                  {numeral(this.price(category.id)).format(
                    "0,0" + this.props.data.project.currency
                  )}
                  {" " + this.props.data.project.currency}
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
        <hr />

        {this.total()}
        <Header size="medium">
          <Table basic="very">
            <Table.Body>
              <Table.Row>
                <Table.Cell>
                  Total {this.props.data.totalSummary.totalMd + "MD "}
                </Table.Cell>
                <Table.Cell textAlign="right">
                  {numeral(this.props.data.totalSummary.totalPrice).format(
                    "0,0"
                  )}
                  {" " + this.props.data.project.currency}
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </Header>
      </div>
    );
  }
}

export default Summary;
