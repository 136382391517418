import React from "react";
import { Page, Text, View, StyleSheet, Image, Font } from "@react-pdf/renderer";
//import "nested-list/public/fonts/Adieu-Bold.otf";

Font.register({
  family: "Adieu",
  fonts: [
    {
      src: `fonts/Adieu-Bold.ttf`,
      fontWeight: "bold",
      fontStyle: "normal"
    }
  ]
});
Font.register({
  family: "Sailec",
  fonts: [
    {
      src: `fonts/Sailec-Light.ttf`,
      fontWeight: "normal",
      fontStyle: "normal"
    }
  ]
});
const styles = StyleSheet.create({
  page: { backgroundColor: "#FEC527" },
  section: { textAlign: "left", margin: 50 },
  logo: { width: "50%" },
  line: { width: "50%", paddingTop: "20px" },
  mainText: { paddingTop: "200px" },
  title: { fontFamily: "Adieu", fontSize: 50 },
  estimate: { paddingTop: "30px", fontFamily: "Sailec", fontSize: 24 },
  divider: { color: "black", border: "2px", width: "50%" },
  address: {
    fontFamily: "Sailec",
    fontSize: 11,
    position: "absolute",
    margin: 50,
    left: 0,
    bottom: 0
  }
});

export default function TitlePage(props) {
  return (
    <Page style={styles.page}>
      <View style={styles.section}>
        <Image src="cleevio_logo.png" style={styles.logo} />
        <View style={styles.mainText}>
          <Text style={styles.title}>{props.data.project.projectName}</Text>
          <Image src="Line.png" style={styles.line} />
          <Text style={styles.estimate}>{props.data.company.companyName}</Text>
        </View>
      </View>
      <View style={styles.address}>
        <Text>Cleevio s.r.o.</Text>
        <Text>Rašínovo nábřeží 71/10</Text>
        <Text>Vyšehrad</Text>
        <Text>Praha 128 000</Text>
      </View>
    </Page>
  );
}
